<template>
  <div
    id="competition-type4"
    :class="data.block"
    class="competition h-295px my-2"
  >
    <!-- 遊戲日期 -->
    <swiper
      ref="competitionTab"
      :options="tabSwiperOption"
      class="tabSwiper h-44px"
      @slideChange="onSlide"
    >
      <swiper-slide
        v-for="(competitionDate, index) in filterCompetitionDate"
        :key="index"
      >
        <div
          class="cursor-pointer d-flex rounded align-center justify-end h-100"
        >
          <!-- 日期 -->
          <div class="caption icon--text w-100 px-4 d-flex justify-center align-center font-weight-bold">
            <Icon
              data="@icon/date.svg"
              width="16"
              height="16"
              class="icon--text mr-2"
            />
            {{ competitionDate }}
          </div>
        </div>
      </swiper-slide>

      <!-- 上一頁 -->
      <div
        slot="button-prev"
        class="swiper-button-prev"
        @click="slidePrev"
      >
        <v-icon
          medium
          class="icon--text"
        >
          mdi-chevron-left
        </v-icon>
      </div>

      <!-- 下一頁 -->
      <div
        slot="button-next"
        class="swiper-button-next"
        @click="sliderNext"
      >
        <v-icon
          medium
          class="icon--text"
        >
          mdi-chevron-right
        </v-icon>
      </div>
    </swiper>

    <!-- 列表 -->
    <div>
      <swiper
        ref="competitionItem"
        class="swiper h-247px rounded"
        :options="competitionSwiperOption"
        @slideChange="competitionItemOnSlide"
      >
        <swiper-slide
          v-for="competition in filterCompetitionItem"
          :key="competition._id"
          class="overflow-y-auto"
        >
          <!-- 列表 -->
          <div
            v-for="item in competition"
            :key="item._id"
            class="card1 my-2 rounded h-125px pt-2 box-shadow mx-4"
          >
            <!-- 遊戲類別 -->
            <div class="d-flex pl-3 caption font-weight-bold primary--text">
              <div class="logo mr-2">
                <v-img
                  max-width="20"
                  max-height="20"
                  :src="item.game_logo"
                />
              </div>
              <!-- 類別 -->
              {{ item.competition_name }}
            </div>

            <!-- 賽程項目 -->
            <div class="competion__content d-flex justify-space-between align-center px-4 h-85px">
              <!-- 主場 -->
              <div class="competion__content-home w-30 d-flex flex-column align-center text-center">
                <v-img
                  max-width="32"
                  max-height="32"
                  :src="item.home.team_logo"
                />
                <span class="caption title--text mt-2">{{ item.home.team_name }}</span>
              </div>

              <!-- 比分 / 時間-->
              <div class="competion__content-num warning--text font-weight-bold text-center">
                <div class="subtitle-1 secondary--text font-weight-medium">
                  {{ item.start_datetime.split(' ')[1].slice(0,5) }}
                </div>
              </div>

              <!-- 客場 -->
              <div class="competion__content-away w-30 d-flex justify-center flex-column text-center align-center">
                <v-img
                  max-width="32"
                  max-height="32"
                  :src="item.away.team_logo"
                />
                <span class="caption title--text mt-2">{{ item.away.team_name }}</span>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({}),

  // 賽事資料
  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    /**
     * 所有賽程資料
     * @date 2021-07-19
     * @returns {array}
     */
    competitions() {
      return this.data.data[this.data.type]
    },

    /**
     * 賽程資料日期 for tab
     * @date 2021-07-19
     * @returns {array}
     */
    filterCompetitionDate() {
      const competionDateAry = []
      this.data.data[this.data.type].forEach(item => {
        const competitionDate = item.start_datetime.split(' ')[0]
        if (competionDateAry.includes(competitionDate)) return false
        competionDateAry.push(competitionDate)
      })
      return competionDateAry
    },

    /**
     * 依日期篩選 賽程
     * @date 2021-07-19
     * @returns {object}
     */
    filterCompetitionItem() {
      const competitionItems = {}
      this.filterCompetitionDate.forEach((date) => {
        competitionItems[date] = this.competitions.filter(item => item.start_datetime.split(' ')[0] === date)
      })

      return competitionItems
    },

    /**
     * 賽事類別 tab swiper slide 設置
     * @date 2021-07-19
     * @returns {object}
     */
    tabSwiperOption() {
      return {
        autoplay: {
          delay: 3000,
        },
        slidesPerView: 1,
        spaceBetween: 8,
        centeredSlides: true,
        centeredSlidesBounds: true,

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    },

    /**
     * 賽事項目 swiper 設置
     * @date 2021-07-19
     * @returns {object}
     */
    competitionSwiperOption() {
      return {
        autoplay: {
          delay: 3000,
        },
      }
    },

    // item background
    bg() {
      return {
        light: { background: 'rgba(206, 206, 206, 0.12)' },
        dark: { background: 'rgba(0, 0, 0, 0.25)' },
      }
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },

  },

  methods: {
    /**
     * 賽程類別 tab 滑動執行
     * @date 2021-07-19
     */
    onSlide() {
      this.$refs.competitionItem.$swiper.slideTo(this.$refs.competitionTab.$swiper.activeIndex, 500)
    },

    /**
     * 賽事項目 滑動執行
     * @date 2021-07-19
     */
    competitionItemOnSlide() {
      this.$refs.competitionTab.$swiper.slideTo(this.$refs.competitionItem.$swiper.activeIndex, 500)
    },

    sliderNext() {
      this.$refs.competitionItem.$swiper.slideNext()
    },
    slidePrev() {
      this.$refs.competitionItem.$swiper.slidePrev()
    },
  },
}
</script>

<style lang="scss" scoped>
.h-44px {
	height: 44px;
}

.h-295px {
	height: 295px;
}

.h-247px {
	height: 247px;
}

.h-85px {
	height: 85px;
}

.w-30 {
	width: 30%;
}

.h-125px {
	height: 125px;
}

.competion__content {
	&-home,
	&-away {
		.caption {
			line-height: 14px;
		}
	}
}

.box-shadow {
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.swiper-button-prev,
.swiper-button-next {
	&::after {
		display: none;
	}

	i {
		font-size: 30px;
	}
}
</style>
